import fetch from '@/utils/fetch';

export function sendFeedback({
	form,
	isFeedback
}) {
	return fetch({
		url: '/',
		method: 'post',
		data: {
			form,
			isFeedback
		}
	});
}