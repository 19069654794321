import store from '@/store';

export const browserInfoMixin = {
	data() {
		return {
			browserDetails: {}
		};
	},
	methods: {
		getCookies() {
			const pairs = document.cookie.split(';');
			const cookies = {};
			for (let i = 0; i < pairs.length; i++) {
				const pair = pairs[i].split('=');
				cookies[(pair[0] + '').trim()] = unescape(pair[1]);
			}
			return cookies;
		}
	},
	mounted() {
		this.browserDetails.appCodeName = navigator.appCodeName;
		this.browserDetails.appName = navigator.appName;
		this.browserDetails.appVersion = navigator.appVersion;
		this.browserDetails.cookieEnabled = navigator.cookieEnabled;
		this.browserDetails.onLine = navigator.onLine;
		this.browserDetails.platform = navigator.platform;
		this.browserDetails.userAgent = navigator.userAgent;

		this.browserDetails.plugins = [];
		for (let i = 0; i < navigator.plugins.length; i++) {
			this.browserDetails.plugins.push(navigator.plugins[i].name);
		}

		this.browserDetails.route = store.state.route;
		this.browserDetails.url = location.href;
		this.browserDetails.cookies = this.getCookies();
		if (typeof window.performance !== 'undefined' && typeof window.performance.memory !== 'undefined') {
			this.browserDetails.memory = window.performance.memory;
		}
	}
};