import { render, staticRenderFns } from "./FullFeedback.vue?vue&type=template&id=285cb903&scoped=true"
import script from "./FullFeedback.vue?vue&type=script&lang=js"
export * from "./FullFeedback.vue?vue&type=script&lang=js"
import style0 from "./FullFeedback.vue?vue&type=style&index=0&id=285cb903&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "285cb903",
  null
  
)

export default component.exports