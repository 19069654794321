<template>
    <div v-loading="loading">
        <el-steps :active="step">
            <el-step title="Step 1"></el-step>
            <el-step title="Step 2"></el-step>
            <el-step title="Step 3"></el-step>
        </el-steps>
        <div class="steps">
            <div class="stepOne" v-show="step === 0">
                <p class="desc">{{ $t('feedback.step1-desc') }}</p>
                <div class="highlight">
                    <el-button
                        size="mini"
                        @click="setSelectType(1)"
                        class="btn btn-set-select-type-1"
                        type="primary"
                        :plain="selectButton(1)"
                        >{{ $t('feedback.highlight') }}</el-button
                    >
                    <span class="desc">{{ $t('feedback.step1-desc-2') }}</span>
                </div>
                <div class="blackout">
                    <el-button
                        size="mini"
                        @click="setSelectType(2)"
                        class="btn btn-set-select-type-2"
                        type="primary"
                        :plain="selectButton(2)"
                        >{{ $t('feedback.blackout') }}</el-button
                    >
                    <span class="desc">{{ $t('feedback.step1-desc-3') }}</span>
                </div>
            </div>
            <div class="stepTwo" v-show="step === 1">
                <h3 class="desc">{{ $t('feedback.desc') }}</h3>
                <el-input
                    type="textarea"
                    :rows="4"
                    :placeholder="$t('feedback.desc-ph')"
                    @input="$emit('input', $event)"
                    :value="message"
                    v-model="childMessage"
                >
                </el-input>
                <h3 class="desc">{{ $t('feedback.additonal') }}</h3>
                <br />
                <Dropzone ref="dzone"> </Dropzone>
            </div>
            <div class="stepThree" v-show="step === 2">
                <h3 class="desc">{{ $t('feedback.summary') }}</h3>
                <div class="summary-wrapper">
                    <div class="left">
                        <div class="message-desc">
                            <h4>{{ $t('feedback.msg') }}</h4>
                            <span v-html="childMessage"> </span>
                        </div>
                        <div class="additional-info">
                            <h4>{{ $t('feedback.additional') }}</h4>
                            <ul>
                                <li>{{ $t('feedback.ad-info') }}</li>
                                <li>{{ $t('feedback.ad-page') }}</li>
                                <li>{{ $t('feedback.ad-str') }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="right">
                        <div class="canvas-img">
                            <img :src="canvasImage" alt="Screenshot" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas';
import $ from 'jquery';
import { browserInfoMixin } from '@/mixins/browserInfo';
import blankImage from '@/assets/images/blank.png';
const DropzoneComp = () => import('@/components/Dropzone/Index.vue');
import { sendFeedback } from '@/api/feedback';

import { EventBus } from '@/utils/Bus.js';

import { mapGetters } from 'vuex';
export default {
    props: ['step', 'message'],
    name: 'fullFeedback',
    mixins: [browserInfoMixin],
    components: {
        Dropzone: DropzoneComp
    },
    data() {
        return {
            settings: {
                strokeStyle: 'black',
                shadowColor: 'black',
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 10,
                lineJoin: 'bevel',
                lineWidth: 3
            },
            activeStep: 0,
            captureEl: 'body',
            canDraw: false,
            width: null,
            height: null,
            ctx: null,
            rect: {},
            drag: false,
            draw: 1,
            post: {},
            drawType: {
                1: 'highlight',
                2: 'blackout'
            },
            canvas: {
                width: '',
                height: ''
            },
            html2canvasOptions: {
                logging: false,
                javascriptEnabled: true,
                // foreignObjectRendering: true,
                removeContainer: true,
                useCORS: true,
                imageTimeout: 0,
                allowTaint: true // you can add this property
            },
            childMessage: '',
            canvasImage: blankImage,
            loading: false
        };
    },
    methods: {
        selectButton(number) {
            return this.draw !== number;
        },
        setSelectType(type) {
            this.draw = type;
        },
        createInitialCanvas() {
            const canvas = document.createElement('canvas');
            canvas.id = 'feedback-canvas';
            document.querySelector(this.captureEl).appendChild(canvas);
            const canvasAttr = {
                width: this.width,
                height: this.height
            };
            $('#feedback-canvas')
                .attr(canvasAttr)
                .css('z-index', '2000');
            $('#feedback-canvas').css('cursor', 'crosshair');
            this.ctx = canvas.getContext('2d');
            this.canvas.width = $('#feedback-canvas').width();
            this.canvas.height = $('#feedback-canvas').height();
            this.ctx.fillStyle = 'rgba(102,102,102,0.5)';
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
            this.currentCanvas = canvas;
            $(document).on('mousedown.feedback', '#feedback-canvas', this.drawRectDown);
            $(document).on('mouseup.feedback', '#feedback-canvas', this.drawRectUp);
            $(document).on('mousemove.feedback', '#feedback-canvas', this.drawRectMove);
            $(document).on('mouseleave.feedback', 'body,#feedback-canvas', this.redraw);
            $(document).on('mouseenter.feedback', '.feedback-helper', this.redraw);
            $(document).on('mouseenter.feedback mouseleave.feedback', '.feedback-helper', this.enterLeaveHelper);
            $(document).on('click.feedback', '#feedback-close', this.closeFeedbackHelper);
        },
        initializeCanvas() {
            this.width = $(document).width();
            this.height = $(document).height();
            this.createInitialCanvas();
            this.canDraw = true;
        },
        closeFeedbackHelper(e) {
            const el = e.currentTarget;
            $(el)
                .parent()
                .remove();
            this.redraw();
        },
        enterLeaveHelper(e) {
            if (this.drag) return false;
            this.rect.w = 0;
            this.rect.h = 0;
            const el = e.currentTarget;
            if (e.type === 'mouseenter') {
                el.style.zIndex = 3001;
                const helperInner = document.createElement('div');
                helperInner.className = 'feedback-helper-inner';
                helperInner.style.width = `${$(el).width() - 2}px`;
                helperInner.style.height = `${$(el).height() - 2}px`;
                helperInner.style.position = 'absolute';
                helperInner.style.margin = '1px';
                el.appendChild(helperInner);

                const feedbackClose = document.createElement('i');
                feedbackClose.id = 'feedback-close';
                feedbackClose.className = 'el-icon-circle-close';
                el.appendChild(feedbackClose);
                feedbackClose.style.top = `${-1 *
                    ($(el)
                        .find('#feedback-close')
                        .height() /
                        2 +
                        5)}px`;
                feedbackClose.style.left = `${$(el).width() -
                    ($(el)
                        .find('#feedback-close')
                        .width() /
                        2 -
                        3)}px`;

                if ($(el).attr('data-type') == 'blackout') {
                    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    this.ctx.fillStyle = 'rgba(102,102,102,0.5)';
                    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

                    $('.feedback-helper').each((index, lowEl) => {
                        if ($(lowEl).attr('data-type') == 'highlight') {
                            this.drawLines(
                                parseInt($(lowEl).css('left'), 10),
                                parseInt($(lowEl).css('top'), 10),
                                $(lowEl).width(),
                                $(lowEl).height()
                            );
                        }
                    });
                    $('.feedback-helper').each((index, lowEl) => {
                        if ($(lowEl).attr('data-type') == 'highlight') {
                            this.ctx.clearRect(
                                parseInt($(lowEl).css('left'), 10),
                                parseInt($(lowEl).css('top'), 10),
                                $(lowEl).width(),
                                $(lowEl).height()
                            );
                        }
                    });
                    this.ctx.clearRect(
                        parseInt($(el).css('left'), 10),
                        parseInt($(el).css('top'), 10),
                        $(el).width(),
                        $(el).height()
                    );
                    this.ctx.fillStyle = 'rgba(0,0,0,0.75)';
                    this.ctx.fillRect(
                        parseInt($(el).css('left'), 10),
                        parseInt($(el).css('top'), 10),
                        $(el).width(),
                        $(el).height()
                    );

                    const ignore = $(el).attr('data-time');

                    $('.feedback-helper').each((index, lowEl) => {
                        if ($(lowEl).attr('data-time') == ignore) {
                            return true;
                        }
                        if ($(lowEl).attr('data-type') == 'blackout') {
                            this.ctx.fillStyle = 'rgba(0,0,0,1)';
                            this.ctx.fillRect(
                                parseInt($(lowEl).css('left'), 10),
                                parseInt($(lowEl).css('top'), 10),
                                $(lowEl).width(),
                                $(lowEl).height()
                            );
                        }
                    });
                }
            } else {
                el.style.zIndex = 3000;
                $(el)
                    .children()
                    .remove();
                if ($(el).attr('data-type') == 'blackout') {
                    this.redraw();
                }
            }
        },
        drawRectDown(e) {
            if (this.canDraw) {
                this.rect.startX = e.pageX - $('#feedback-canvas').offset().left;
                this.rect.startY = e.pageY - $('#feedback-canvas').offset().top;
                this.rect.w = 0;
                this.rect.h = 0;
                this.drag = true;
            }
        },
        drawRectUp(e) {
            if (this.canDraw) {
                this.drag = false;
                let dtop = this.rect.startY;
                let dleft = this.rect.startX;
                let dwidth = this.rect.w;
                let dheight = this.rect.h;

                if (dwidth == 0 || dheight == 0) return;
                if (dwidth < 0) {
                    dleft += dwidth;
                    dwidth *= -1;
                }
                if (dheight < 0) {
                    dtop += dheight;
                    dheight *= -1;
                }

                if (dtop + dheight > this.height) {
                    dheight = this.height - dtop;
                }
                if (dleft + dwidth > this.width) {
                    dwidth = this.width - dleft;
                }

                this.createHelper(dtop, dleft, dwidth, dheight);
                this.redraw();
                this.rect.w = 0;
            }
        },
        drawRectMove(e) {
            if (this.canDraw && this.drag) {
                $('#feedback-highlighter').css('cursor', 'default');
                this.rect.w = e.pageX - $('#feedback-canvas').offset().left - this.rect.startX;
                this.rect.h = e.pageY - $('#feedback-canvas').offset().top - this.rect.startY;
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.ctx.fillStyle = 'rgba(102,102,102,0.5)';
                this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

                $('.feedback-helper').each((index, el) => {
                    if ($(el).attr('data-type') == 'highlight') {
                        this.drawLines(
                            parseInt($(el).css('left'), 10),
                            parseInt($(el).css('top'), 10),
                            $(el).width(),
                            $(el).height()
                        );
                    }
                });

                if (this.draw == 1) {
                    this.drawLines(this.rect.startX, this.rect.startY, this.rect.w, this.rect.h);
                    this.ctx.clearRect(this.rect.startX, this.rect.startY, this.rect.w, this.rect.h);
                }

                $('.feedback-helper').each((index, el) => {
                    if ($(el).attr('data-type') == 'highlight') {
                        this.ctx.clearRect(
                            parseInt($(el).css('left'), 10),
                            parseInt($(el).css('top'), 10),
                            $(el).width(),
                            $(el).height()
                        );
                    }
                });

                $('.feedback-helper').each((index, el) => {
                    if ($(el).attr('data-type') == 'blackout') {
                        this.ctx.fillStyle = 'rgba(0,0,0,1)';
                        this.ctx.fillRect(
                            parseInt($(el).css('left'), 10),
                            parseInt($(el).css('top'), 10),
                            $(el).width(),
                            $(el).height()
                        );
                    }
                });

                if (this.draw == 2) {
                    this.ctx.fillStyle = 'rgba(0,0,0,0.5)';
                    this.ctx.fillRect(this.rect.startX, this.rect.startY, this.rect.w, this.rect.h);
                }
            }
        },
        redraw(border) {
            border = typeof border !== 'undefined' ? border : true;
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.ctx.fillStyle = 'rgba(102,102,102,0.5)';
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

            $('.feedback-helper').each((index, el) => {
                if ($(el).attr('data-type') == 'highlight') {
                    if (border) {
                        this.drawLines(
                            parseInt($(el).css('left'), 10),
                            parseInt($(el).css('top'), 10),
                            $(el).width(),
                            $(el).height()
                        );
                    }
                }
            });

            $('.feedback-helper').each((index, el) => {
                if ($(el).attr('data-type') == 'highlight') {
                    this.ctx.clearRect(
                        parseInt($(el).css('left'), 10),
                        parseInt($(el).css('top'), 10),
                        $(el).width(),
                        $(el).height()
                    );
                }
            });

            $('.feedback-helper').each((index, el) => {
                if ($(el).attr('data-type') == 'blackout') {
                    this.ctx.fillStyle = 'rgba(0,0,0,1)';
                    this.ctx.fillRect(
                        parseInt($(el).css('left'), 10),
                        parseInt($(el).css('top'), 10),
                        $(el).width(),
                        $(el).height()
                    );
                }
            });
        },
        drawLines(x, y, w, h) {
            this.ctx.strokeStyle = this.settings.strokeStyle;
            this.ctx.shadowColor = this.settings.shadowColor;
            this.ctx.shadowOffsetX = this.settings.shadowOffsetX;
            this.ctx.shadowOffsetY = this.settings.shadowOffsetY;
            this.ctx.shadowBlur = this.settings.shadowBlur;
            this.ctx.lineJoin = this.settings.lineJoin;
            this.ctx.lineWidth = this.settings.lineWidth;

            this.ctx.strokeRect(x, y, w, h);

            this.ctx.shadowOffsetX = 0;
            this.ctx.shadowOffsetY = 0;
            this.ctx.shadowBlur = 0;
            this.ctx.lineWidth = 1;
        },
        createHelper(_y, _x, _w, _h) {
            const helper = document.createElement('div');
            helper.className = 'feedback-helper';
            helper.setAttribute('data-type', this.currentDraw);
            helper.setAttribute('data-time', Date.now());
            helper.style.position = 'absolute';
            helper.style.top = `${_y}px`;
            helper.style.left = `${_x}px`;
            helper.style.width = `${_w}px`;
            helper.style.height = `${_h}px`;
            helper.style.zIndex = 3000;
            document.getElementById('feedback-helpers').appendChild(helper);
        },
        createHelperEl() {
            const highliter = document.createElement('div');
            highliter.id = 'feedback-helpers';
            document.querySelector(this.captureEl).appendChild(highliter);
        },
        generateScreenShot() {
            this.canDraw = false;
            $('#feedback-canvas').css('cursor', 'default');
            $('.feedbackModal').hide();
            const sy = $(document).scrollTop();
            const dh = $(window).height();
            this.loading = true;
            if ($('#feedback-helpers').children().length === 0) {
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
                this.ctx.fillStyle = 'rgba(0,0,0,0)';
                this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
            }
            html2canvas(document.querySelector(this.captureEl), this.html2canvasOptions).then((canvas) => {
                this.redraw();
                $('.feedbackModal').show();
                this.loading = false;
                const _canvas = $('<canvas id="feedback-canvas-tmp" width="' + this.width + '" height="' + dh + '"/>')
                    .hide()
                    .appendTo('body');
                const _ctx = _canvas.get(0).getContext('2d');
                _ctx.drawImage(canvas, 0, sy, this.width, dh, 0, 0, this.width, dh);
                this.canvasImage = _canvas.get(0).toDataURL();
                _canvas.remove();
            });
        },
        resumeHighlight() {
            this.canDraw = true;
            $('#feedback-canvas').css('cursor', 'crosshair');
        },
        restrictHighlight() {
            this.canDraw = false;
            $('#feedback-canvas').css('cursor', 'default');
        },
        async sendFeedback() {
            if (this.childMessage.length < 5) {
                this.sbMsg({
                    message: this.$t('feedback.desc-error'),
                    type: 'error'
                });
                return false;
            }
            const form = new FormData();
            form.append('browserinfo', JSON.stringify(this.browserDetails));
            form.append('message', this.childMessage);
            form.append('image', this.canvasImage);
            form.append('isFeedback', true);
            form.append('action', 'sendFeedback');
            form.append('userEmail', this.userEmail);
            form.append('userId', this.userId);
            form.append('userName', this.userName);
            const secondaryImages = this.$refs.dzone.$refs.dropzoneComp.getAcceptedFiles();
            for (const i in secondaryImages) {
                form.append(`file-${i}`, secondaryImages[i]);
            }

            const req = await sendFeedback({
                form,
                isFeedback: true
            });
            if (typeof req.error === 'undefined' || typeof req.message === 'undefined') {
                this.sbMsg({
                    type: 'error',
                    message: this.$t('feedback.feedback-error')
                });
            }
            if (req.error == 1) {
                this.sbMsg({
                    type: 'error',
                    message: req.message
                });
            } else {
                this.sbMsg({
                    type: 'success',
                    message: this.$t('feedback.feedback-sent')
                });
            }
            EventBus.$emit('destroyFeedback');
            return true;
        }
    },
    computed: {
        ...mapGetters({
            userEmail: 'user/userEmail',
            userId: 'user/userId',
            userName: 'user/userName'
        }),
        reactiveStep() {
            return this.step;
        },
        currentDraw() {
            return this.drawType[this.draw];
        }
    },
    watch: {
        step(val) {
            if (val === 2) {
                this.generateScreenShot();
            } else if (val === 0) {
                this.resumeHighlight();
            } else if (val === 1) {
                this.restrictHighlight();
            }
        }
    },
    mounted() {
        this.createHelperEl();
        this.initializeCanvas();

        EventBus.$on('sendfullfeedback', (data) => {
            this.sendFeedback();
        });
    },
    created() {},
    beforeDestroy() {
        $(document).off('mouseenter.feedback mouseleave.feedback', '.feedback-helper');
        $(document).off('mouseup.feedback keyup.feedback');
        $(document).off('mousedown.feedback click.feedback', '#feedback-close');
        $(document).off('mousedown.feedback', '#feedback-canvas');
        $(document).off('mouseleave.feedback', 'body');
        $(document).off('mouseenter.feedback', '.feedback-helper');
        $('#feedback-helpers').remove();
        $('#feedback-canvas').remove();
        EventBus.$off();
    }
};
</script>

<style scoped lang="scss">
.steps {
    padding: 10px 5px;

    p.desc {
        color: #909399;
        font-size: 13px;
        vertical-align: middle;
    }

    h3.desc {
        margin-bottom: 0;
    }

    .stepOne div,
    .stepTwo div {
        padding-top: 20px;
    }

    .stepThree {
        .summary-wrapper {
            display: table;
            width: 100%;

            .left {
                display: table-cell;
                padding-left: 8px;
                width: 48%;

                span {
                    font-size: 12px;
                    word-break: break-all;
                    max-height: 50px;
                    overflow-y: auto;
                    display: block;
                }

                ul {
                    list-style: none;
                    margin-left: 0;
                    padding-left: 0;
                }

                li {
                    margin-bottom: 10px;
                    font-size: 13px;
                    position: relative;
                }

                li:before {
                    content: '\276F';
                    padding-right: 5px;
                }
            }

            .right {
                display: table-cell;
                width: 48%;

                .canvas-img {
                    text-align: center;

                    img {
                        max-width: 100%;
                        padding: 1px;
                        border: 1px solid #adadad;
                        vertical-align: top;
                    }
                }
            }
        }

        h4 {
            margin-bottom: 10px;
        }
    }

    .btn {
        margin-right: 20px;
        vertical-align: middle;
    }
}
</style>
